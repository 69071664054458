import { BuildNavItemFromRoute, BuildRoute, NavItem, RouteError, useNavItemStore } from '@cksoftware/react-base';
import { RouteObject } from 'react-router-dom';
import { NarrowLayout } from '../components/layout/narrowLayout';
import { Home } from '../components/misc/Home';
import { AccountManagementItems, LoginRouteItem, LogoutRouteItem, MyAccountRouteItem } from './AccountRoutes';
import { AdminNavItems } from './AdminRoutes';
import { ReminderRouteItems } from './ReminderRoutes';
import { DacsEventRoutes } from './DacsEventRoutes.tsx';

const AuthenticatedRouteItems = [
  AccountManagementItems,
  MyAccountRouteItem,
  AdminNavItems,
  LogoutRouteItem,
  ReminderRouteItems,
  DacsEventRoutes
];
const UnauthenticatedRouteItems = [LoginRouteItem, AccountManagementItems];

export function useNavWithRouting() {
  const setNavItemStore = useNavItemStore((state) => state.set);
  const routes = new Array<RouteObject>();
  const nav: NavItem[] = [];

  routes.push({
    path: '/',
    element: <NarrowLayout />,
    errorElement: <RouteError />,
    children: [{ path: '/', element: <Home />, errorElement: <RouteError /> }]
  });
  AuthenticatedRouteItems.forEach((value) => {
    routes.push(BuildRoute(value));
    const navItem = BuildNavItemFromRoute(value);
    if (navItem) {
      nav.push(navItem);
    }
  });
  UnauthenticatedRouteItems.forEach((value) => {
    routes.push(BuildRoute(value));
    const navItem = BuildNavItemFromRoute(value);
    if (navItem) {
      nav.push(navItem);
    }
  });

  setNavItemStore(nav);

  return routes;
}
