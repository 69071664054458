import { AdventEvent } from '../types.ts';
import { Box, Flex, Heading } from '@chakra-ui/react';

type Props = {
  event: AdventEvent;
};

export const ClosedBox = (props: Props) => {
  return (
    <Box
      backgroundColor={'black'}
      border='1px solid black'
      backgroundImage={`url(${props.event.Image})`}
      backgroundSize={'cover'}
      display='flex'
      alignItems='center'
      position={'relative'}
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 1
      }}
      justifyContent='center'>
      <Box>
        <Flex
          opacity={1}
          flexDir={'column'}
          alignItems={'center'}
          color={'white'}
          zIndex={'2'}
          textShadow={'-2px -2px 0 #000,2px -2px 0 #000, -2px  2px 0 #000,2px  2px 0 #000;'}>
          <Heading fontSize={'54pt'}>{props.event.DayNumber}</Heading>
        </Flex>
        <Box backgroundColor={'blue'} w={'100%'} zIndex={'0'} height={'100%'} />
      </Box>
    </Box>
  );
};
