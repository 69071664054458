import { Box, BoxProps } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { Nav } from '../../features/header/Nav';
import { AdventCalendar } from '../../features/adventCalendar/adventCalendar.tsx';

type NarrowLayoutProps = {
  containerProps?: BoxProps;
  hideCalendar?: boolean;
};

export const NarrowLayout = (props: NarrowLayoutProps) => {
  return (
    <Box as='section' width='100vw' height='100vh' overflowY='auto'>
      {!props.hideCalendar && <AdventCalendar />}
      <Nav />
      <Box
        w={['100%', '100%', '100%', '100%']}
        margin={'auto'}
        paddingLeft={'5px'}
        paddingRight={'5px'}
        paddingTop={'10px'}
        {...props.containerProps}>
        <Outlet />
      </Box>
    </Box>
  );
};
