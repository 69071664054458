export const ApplicationRoutes = {
  account: {
    myProfile: '/my-profile',
    login: '/login',
    logout: '/logout',
    resetPassword: '/reset-password',
    confirmEmail: '/confirm-email',
    forgotPassword: '/forgot-password'
  },
  admin: {
    users: '/admin/users'
  },
  reminders: {
    manage: '/reminders/manage-reminders'
  },
  dacsEvents: {
    view: '/dacs-events/view',
    timeline: '/dacs-events/timeline',
    random: '/dacs-events/random'
  }
};
