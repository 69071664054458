import { Grid } from '@chakra-ui/react';
import { LoadingSpinner, useAuth } from '@cksoftware/react-base';
import { OrderedAdventDays } from './adventDays.tsx';
import { BucharestBox } from './components/bucharestBox.tsx';
import { ClosedBox } from './components/closedBox.tsx';
import { FinalBox } from './components/finalBox.tsx';
import { OpenBox } from './components/openBox.tsx';
import { TodayBox } from './components/todayBox.tsx';
import { useDaysQuery } from './useDaysQuery.ts';
import { useSearchParams } from 'react-router-dom';

function seededShuffle<T>(array: T[], seed: number): T[] {
  let m = array.length,
    t,
    i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;

    // Advance the seed for the next iteration
    seed++;
  }

  return array;
}

function random(seed: number): number {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

export const AdventCalendar = () => {
  const openDays = useDaysQuery();
  const days = seededShuffle(OrderedAdventDays, 1234);
  const numElements = days.length;
  const auth = useAuth();
  const [params, _] = useSearchParams();
  const overrideToday = params.get('override');
  // Calculate the number of columns and rows based on the number of elements
  const columns = Math.ceil(Math.sqrt(numElements));
  const rows = Math.ceil(numElements / columns);

  if (!auth.isAuthenticated) {
    return <></>;
  }
  if (!openDays.data) {
    return <LoadingSpinner />;
  }

  return (
    <Grid
      templateColumns={{ base: ``, lg: `repeat(${columns}, 1fr)` }}
      templateRows={{ base: ``, lg: `repeat(${rows}, 1fr)` }}
      w='100vw'
      h='100vh'
      zIndex={'modal'}
      position={'absolute'}
      top={0}
      left={0}
      backgroundColor={'transparent'}>
      {days.map((event, index) => {
        const isToday = !!overrideToday || event.DayNumber === new Date().getDate();
        const isPrevious = event.DayNumber < new Date().getDate();
        const matchingResponse = openDays.data.find((r) => r.Day == event.DayNumber);
        if (matchingResponse) {
          return <OpenBox event={event} key={index} alexResponse={matchingResponse.AlexResponse}></OpenBox>;
        }

        if (event.IsBucharest) {
          return <BucharestBox key={index} event={event} isToday={true}></BucharestBox>;
        }
        if (event.IsFinal && isToday) {
          return <FinalBox key={index} event={event} isToday={isToday}></FinalBox>;
        }
        if (isToday || isPrevious) {
          return <TodayBox key={index} event={event}></TodayBox>;
        }
        return <ClosedBox event={event} key={index} />;
      })}
    </Grid>
  );
};
