import { Box, Flex, HStack, Image, Link } from '@chakra-ui/react';
import logo from '../../assets/logo.png';
import { NavMenus } from './NavMenus';

export const Nav = () => {
  return (
    <Box
      backgroundColor={'white'}
      height={'70px'}
      marginBottom={'1px'}
      borderTopWidth={{ base: '5px', lg: '10px', xl: '15px' }}
      borderTopColor={'primary.500'}
      borderStyle={'solid'}
      boxSizing='content-box'
      boxShadow={'0px 2px 4px 0px #80808054'}>
      <Flex
        backgroundColor={'white'}
        w={'100%'}
        h={'100%'}
        alignItems={'center'}
        gap={5}
        justifyContent={'space-between'}>
        <Box paddingLeft={'20px'}>
          <Link href='/'>
            <HStack>
              <Box>
                <Image h={'100%'} maxW={'55px'} src={logo}></Image>
              </Box>
            </HStack>
          </Link>
        </Box>

        <Box marginLeft={'auto'}>
          <NavMenus />
        </Box>
        <Box></Box>
      </Flex>
    </Box>
  );
};
