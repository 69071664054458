import { Alert, Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react';
import {
  ChakraFormDialog,
  createFieldHelper,
  StandardAlert,
  useGenericCrudCreateMutation,
  useHookForm
} from '@cksoftware/react-base';
import { useState } from 'react';
import { AdventEvent, Question, QuestionResponse } from '../types.ts';
import { OpenBox } from './openBox.tsx';
import { AnswerView } from './answerViewr.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';

type Props = {
  event: AdventEvent;
};

export const TodayBox = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const questionDisclosure = useDisclosure();
  const answerDisclosure = useDisclosure();
  const crudCreateMutation = useGenericCrudCreateMutation<QuestionResponse>('QuestionResponse');

  const { formMethods } = useHookForm<Question>();
  const fieldHelper = createFieldHelper<Question>(formMethods);
  const fields = [
    fieldHelper
      .richTextInput('AlexResponse')
      .withLabel(props.event.Question?.Question)
      .withLabelTextProps({ fontSize: '20px' })
      .withInputProps({ height: '200px' })
      .withValidation({ required: { message: 'Trying to cheat bb? You have to answer this question!' } })
  ];

  const handleSave = async (data: Question) => {
    const response: QuestionResponse = {
      AlexResponse: data.AlexResponse,
      Day: props.event.DayNumber,
      Question: props.event.Question?.Question!,
      DustinResponse: props.event.Question?.DustinResponse!
    };

    await crudCreateMutation.mutateAsync(response);
    setIsOpen(true);
    questionDisclosure.onClose();
    answerDisclosure.onOpen();
  };

  return (
    <>
      {isOpen && <OpenBox event={props.event} alexResponse={''} />}
      {!isOpen && (
        <Box
          onClick={questionDisclosure.onOpen}
          cursor={'pointer'}
          backgroundColor={'black'}
          border='1px solid black'
          backgroundImage={`url(${props.event.Image})`}
          backgroundSize={'cover'}
          display='flex'
          alignItems='center'
          position={'relative'}
          justifyContent={'center'}
          zIndex={'99'}
          boxShadow={`0px 0px 2px #ec1c2f, 0 0 30px #ec1c2f, 0 0 45px rgba(255, 69, 0, 0.4), 0 0 60px rgba(255, 69, 0, 0.2)`}>
          <Box>
            <Flex
              opacity={1}
              flexDir={'column'}
              alignItems={'center'}
              color={'white'}
              zIndex={'2'}
              textShadow={'-2px -2px 0 #000,2px -2px 0 #000, -2px  2px 0 #000,2px  2px 0 #000;'}>
              <Heading fontSize={'54pt'}>{props.event.DayNumber}</Heading>
              <Box fontSize={'25pt'}>😘 🥰😍 ❤️‍ 🔥 😘🥰 😍 ❤️‍ 🔥</Box>
              <Heading fontSize={'45pt'}>Open Me</Heading>
            </Flex>
            <Box backgroundColor={'blue'} w={'100%'} zIndex={'0'} height={'100%'} />
          </Box>
        </Box>
      )}
      <ChakraFormDialog onSubmit={handleSave} formMethods={formMethods} disclosure={questionDisclosure}>
        <Flex flexDir={'column'} gap={'20px'} marginBottom={'10px'}>
          {props.event.Question?.Comment && <StandardAlert>{props.event.Question?.Comment}</StandardAlert>}
          {fieldHelper.renderFieldHelperInput('AlexResponse', fields)}
        </Flex>
        <Alert paddingTop={'10px'} backgroundColor={'secondary.500'} color={'white'} borderRadius={'full'}>
          <Box fontSize={'md'}>
            <Icon as={FontAwesomeIcon} icon={faHeartbeat} color={'primary.500'}></Icon> {props.event.Title}
          </Box>
        </Alert>
      </ChakraFormDialog>
      <AnswerView event={props.event} disclosure={answerDisclosure} />
    </>
  );
};
