import { useQuery } from '@tanstack/react-query';
import { getAxios } from '../../util/axios.ts';

export const useDaysQuery = () => {
  return useQuery({
    queryKey: ['days'],
    queryFn: async () => {
      return (await getAxios().get<DayResponseModel[]>('questionresponse/days')).data;
    }
  });
};

type DayResponseModel = {
  Day: number;
  AlexResponse: string;
};
