import { AspectRatio, HStack, Image, Skeleton, Stack, StackProps, useBreakpointValue } from '@chakra-ui/react';
import { SetStateAction, useState } from 'react';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { Carousel, CarouselIconButton, CarouselSlide, useCarousel } from './Carousel';

interface GalleryProps {
  images: string[];
  aspectRatio?: number;
  rootProps?: StackProps;
}

export const Gallery = (props: GalleryProps) => {
  const { images, aspectRatio = 4 / 3, rootProps } = props;
  const [index, setIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesPerView = useBreakpointValue({ base: 3, md: 5 });

  const [ref, slider] = useCarousel({
    slides: {
      perView: slidesPerView,
      spacing: useBreakpointValue({ base: 16, md: 24 })
    },
    slideChanged: (slider: { track: { details: { rel: SetStateAction<number> } } }) =>
      setCurrentSlide(slider.track.details.rel)
  });

  return (
    <Stack spacing='4' {...rootProps}>
      <AspectRatio ratio={aspectRatio}>
        <Image src={images[index]} alt={'alt'} objectFit={'contain !important' as any} fallback={<Skeleton />} />
      </AspectRatio>
      <HStack spacing='4'>
        <CarouselIconButton
          onClick={() => slider.current?.prev()}
          icon={<IoChevronBackOutline />}
          aria-label='Previous slide'
          disabled={currentSlide === 0}
        />
        <Carousel ref={ref} direction='row' width='full'>
          {images.map((image, i) => (
            <CarouselSlide key={i} onClick={() => setIndex(i)} cursor='pointer'>
              <AspectRatio
                ratio={aspectRatio}
                transition='all 200ms'
                opacity={index === i ? 1 : 0.4}
                _hover={{ opacity: 1 }}>
                <Image src={image} objectFit='cover' alt={'alt'} fallback={<Skeleton />} />
              </AspectRatio>
            </CarouselSlide>
          ))}
        </Carousel>
        <CarouselIconButton
          onClick={() => slider.current?.next()}
          icon={<IoChevronForwardOutline />}
          aria-label='Next slide'
          disabled={currentSlide + Number(slidesPerView) === images.length}
        />
      </HStack>
    </Stack>
  );
};
