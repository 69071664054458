import { AdventEvent, Question, QuestionResponse } from '../types.ts';
import { Alert, Box, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react';
import {
  ChakraFormDialog,
  createFieldHelper,
  StandardAlert,
  useGenericCrudCreateMutation,
  useHookForm
} from '@cksoftware/react-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { AnswerView } from './answerViewr.tsx';
import { OpenBox } from './openBox.tsx';
import { useRef, useState } from 'react';
import { Fireworks, FireworksHandlers } from '@fireworks-js/react';

type Props = {
  event: AdventEvent;
  isToday: boolean;
};

export const BucharestBox = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showFireworks, setShowFireworks] = useState(false);
  const ref = useRef<FireworksHandlers>(null);

  const toggle = () => {
    if (!ref.current) return;
    if (ref.current.isRunning) {
      ref.current.stop();
    } else {
      ref.current.start();
    }
  };

  const questionDisclosure = useDisclosure();
  const answerDisclosure = useDisclosure();
  const crudCreateMutation = useGenericCrudCreateMutation<QuestionResponse>('QuestionResponse');

  const { formMethods } = useHookForm<Question>();
  const fieldHelper = createFieldHelper<Question>(formMethods);
  const fields = [
    fieldHelper
      .richTextInput('AlexResponse')
      .withLabel(props.event.Question?.Question)
      .withLabelTextProps({ fontSize: '20px' })
      .withInputProps({ height: '200px' })
      .withValidation({ required: { message: 'Trying to cheat bb? You have to answer this question!' } })
  ];

  const handleSave = async (data: Question) => {
    const response: QuestionResponse = {
      AlexResponse: data.AlexResponse,
      Day: props.event.DayNumber,
      Question: props.event.Question?.Question!,
      DustinResponse: props.event.Question?.DustinResponse!
    };

    await crudCreateMutation.mutateAsync(response);
    setIsOpen(true);
    questionDisclosure.onClose();
    answerDisclosure.onOpen();
  };

  return (
    <>
      {isOpen && <OpenBox event={props.event} alexResponse={''} />}
      {!isOpen && (
        <Box
          backgroundColor={'black'}
          onClick={() => {
            toggle();
            setShowFireworks(true);

            setTimeout(() => {
              setShowFireworks(false);
              questionDisclosure.onOpen();
            }, 7500);
          }}
          cursor={'pointer'}
          border='1px solid black'
          backgroundImage={`url(${props.event.Image})`}
          backgroundSize={'cover'}
          display='flex'
          alignItems='center'
          zIndex={'99'}
          boxShadow={
            props.isToday
              ? `0px 0px 2px #ec1c2f, 0 0 30px #ec1c2f, 0 0 45px rgba(255, 69, 0, 0.4), 0 0 60px rgba(255, 69, 0, 0.2)`
              : 'unset'
          }
          position={'relative'}
          // _before={{
          //   content: '""',
          //   position: 'absolute',
          //   top: 0,
          //   left: 0,
          //   right: 0,
          //   bottom: 0,
          //   backgroundColor: 'rgba(0, 0, 0, 0)',
          //   zIndex: 1
          // }}
          justifyContent='center'>
          <Box>
            {props.isToday && (
              <Flex
                opacity={1}
                flexDir={'column'}
                alignItems={'center'}
                color={'white'}
                zIndex={'2'}
                textShadow={'-2px -2px 0 #000,2px -1px 0 #000, -2px  2px 0 #000,2px  2px 0 #000;'}>
                <Heading>BABY OUR TRIP STARTS TODAY 🛬🛬🛬 </Heading>
                <Box fontSize={'24px'}>If your seeing this it means I get to see you soon</Box>
                <Box fontSize={'24px'}>😘 😘 😘 😘</Box>
                <Box fontSize={'24px'}>(Still open me tho)</Box>
              </Flex>
            )}
          </Box>
          <ChakraFormDialog onSubmit={handleSave} formMethods={formMethods} disclosure={questionDisclosure}>
            <Flex flexDir={'column'} gap={'20px'} marginBottom={'10px'}>
              {props.event.Question?.Comment && <StandardAlert>{props.event.Question?.Comment}</StandardAlert>}
              {fieldHelper.renderFieldHelperInput('AlexResponse', fields)}
            </Flex>
            <Alert paddingTop={'10px'} backgroundColor={'secondary.500'} color={'white'} borderRadius={'full'}>
              <Box fontSize={'md'}>
                <Icon as={FontAwesomeIcon} icon={faHeartbeat} color={'primary.500'}></Icon> {props.event.Title}
              </Box>
            </Alert>
          </ChakraFormDialog>
          {showFireworks && (
            <Fireworks
              ref={ref}
              options={{
                opacity: 0,
                sound: {
                  enabled: true
                }
              }}
              autostart={true}
              style={{
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                position: 'fixed',
                background: '#000'
              }}
            />
          )}
          <AnswerView event={props.event} disclosure={answerDisclosure} />
        </Box>
      )}
    </>
  );
};
