import { AdventEvent } from '../types.ts';
import { Box, Button, Divider, Flex, HStack, Icon, Stack, Text, UseDisclosureReturn, VStack } from '@chakra-ui/react';
import { ChakraDialog } from '@cksoftware/react-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faHeart } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

type Props = {
  event: AdventEvent;
  disclosure: UseDisclosureReturn;
  alexResponse?: string;
};

export const AnswerView = (props: Props) => {
  const nav = useNavigate();
  return (
    <ChakraDialog
      disclosure={props.disclosure}
      header={'🌎♾️DACS4EVER🌎♾️'}
      hideCloseButton={false}
      footerRender={
        <Flex flexDir={'column'} gap={'4px'}>
          {props.event.Question?.BonusLink && (
            <Button
              size='lg'
              onClick={() => {
                nav(props.event.Question?.BonusLink!);
              }}
              leftIcon={<Box>🎁</Box>}
              colorScheme='teal'
              bgGradient='linear(to-r, primary.500, secondary.500)'
              boxShadow='0px 0px 20px rgba(0, 255, 170, 0.6)'
              _hover={{
                bgGradient: 'linear(to-r, green.500, teal.500)',
                boxShadow: '0px 0px 30px rgba(0, 255, 170, 0.8)'
              }}
              animation='pulse 2s infinite'
              sx={{
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)' },
                  '50%': { transform: 'scale(1.05)' },
                  '100%': { transform: 'scale(1)' }
                }
              }}>
              Bonus
            </Button>
          )}
          <Button
            variant={'secondary'}
            onClick={props.disclosure.onClose}
            leftIcon={<Box>💘</Box>}
            rightIcon={<Box>💘</Box>}>
            Close
          </Button>
        </Flex>
      }>
      <Box>
        <VStack alignItems={'flex-start'}>
          <HStack>
            <Icon as={FontAwesomeIcon} icon={faFire} color={'secondary.500'}></Icon>
            <Text fontWeight='medium'>Question: </Text>{' '}
            <Box dangerouslySetInnerHTML={{ __html: props.event.Question?.Question! }}></Box>
          </HStack>
          <Divider />
          <Stack spacing='2'>
            <Box color='fg.muted' lineHeight='tall' fontSize={'sm'}></Box>
          </Stack>
        </VStack>
      </Box>
      <Box maxH={{ base: '400px' }}>
        <VStack alignItems={'flex-start'}>
          <HStack>
            <Icon as={FontAwesomeIcon} icon={faHeart} color={'secondary.500'}></Icon>
            <Text fontWeight='medium'>Dustin said:</Text>
          </HStack>
          <Divider />
          <Stack spacing='2'>
            <Box color='fg.muted' lineHeight='tall' fontSize={'sm'}>
              <Box dangerouslySetInnerHTML={{ __html: props.event.Question?.DustinResponse! }} fontSize={'lg'}></Box>
            </Box>
          </Stack>
        </VStack>
      </Box>
      {props.alexResponse && (
        <Box maxH={{ base: '400px' }} marginTop={'15px'}>
          <VStack alignItems={'flex-start'}>
            <HStack>
              <Icon as={FontAwesomeIcon} icon={faHeart} color={'secondary.500'}></Icon>
              <Text fontWeight='medium'>Alex said:</Text>
            </HStack>
            <Divider />
            <Stack spacing='2'>
              <Box color='fg.muted' lineHeight='tall' fontSize={'sm'}>
                <Box dangerouslySetInnerHTML={{ __html: props.alexResponse! }} fontSize={'lg'}></Box>
              </Box>
            </Stack>
          </VStack>
        </Box>
      )}
    </ChakraDialog>
  );
};
