import { useStandardToast } from '@cksoftware/react-base';
import { PropsWithChildren } from 'react';
import { setAxiosOnError } from './util/axios';
export const ErrorContainer = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const toast = useStandardToast();

  setAxiosOnError((error) => {
    const url = error?.request?.responseURL ?? 'Unknown';
    const statusCode = error?.response?.status ?? 'Unknown';
    const message = error?.message ?? 'Unknown';
    toast.errorToast('API Call Failed', `The API call to ${url} failed with code ${statusCode} and message ${message}`);
  });

  return <>{children}</>;
};
