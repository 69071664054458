import { CardPageContainer, useGenericCrudListQuery } from '@cksoftware/react-base';
import { DacsEvent } from './types.ts';
import { Chrono } from 'react-chrono';
import { useMemo } from 'react';
import { Box, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { formatDateDateOnly } from '../../util/dates.ts';
import dayjs from 'dayjs';
import { Gallery } from '../../components/common/Gallery.tsx';

export const Timeline = () => {
  const eventQuery = useGenericCrudListQuery<DacsEvent>('DacsEvent', 0, 1000, false);
  const chronoItems = useMemo(() => {
    if (!eventQuery.data) {
      return [];
    }
    return eventQuery.data.PagedRows.map((val) => {
      return {
        title: val.Date,
        cardTitle: val.Header,
        cardSubTitle: ''
      };
    });
  }, [eventQuery.data]);
  const chronoRenders = useMemo(() => {
    return eventQuery.data?.PagedRows.map((val, key) => {
      return (
        <CardPageContainer
          key={key}
          containerProps={{ height: '90vh' }}
          header={
            <Flex justifyContent={'space-between'}>
              <Flex flexDir={'column'}>
                <Heading>
                  <Icon color={'primary.500'} as={FontAwesomeIcon} icon={faHeart} /> {val.Header}
                </Heading>
                <Text fontStyle={'italic'}>
                  {formatDateDateOnly(val.Date)}
                  <br /> {dayjs().diff(dayjs(val.Date), 'days')} days ago
                </Text>
              </Flex>
            </Flex>
          }>
          <Flex w={'100%'} flexDir={'column'} justifyItems={'center'}>
            <Box flex={'1'} margin={'auto'}>
              <Gallery images={val.ImageUrls} rootProps={{ boxSize: '30vh' }} />
            </Box>
          </Flex>
        </CardPageContainer>
      );
    });
  }, [eventQuery.data]);
  return (
    <Chrono hideControls={true} items={chronoItems} mode='HORIZONTAL'>
      {chronoRenders}
    </Chrono>
  );
};
