import one from '../../assets/dayphotos/1.jpeg';
import ten from '../../assets/dayphotos/10.jpeg';
import eleven from '../../assets/dayphotos/11.jpeg';
import twelve from '../../assets/dayphotos/12.jpeg';
import thirteen from '../../assets/dayphotos/13.jpeg';
import fourteen from '../../assets/dayphotos/14.jpeg';
import fifteen from '../../assets/dayphotos/15.jpeg';
import sixteen from '../../assets/dayphotos/16.jpeg';
import seventeen from '../../assets/dayphotos/17.jpeg';
import eighteen from '../../assets/dayphotos/18.jpeg';
import nineteen from '../../assets/dayphotos/19.jpeg';
import two from '../../assets/dayphotos/2.jpeg';
import twenty from '../../assets/dayphotos/20.jpeg';
import twentyone from '../../assets/dayphotos/21.jpeg';
import twentytwo from '../../assets/dayphotos/22.jpeg';
import twentythree from '../../assets/dayphotos/23.jpeg';
import twentyfour from '../../assets/dayphotos/24.jpeg';
import twentyfive from '../../assets/dayphotos/25.jpeg';
import three from '../../assets/dayphotos/3.jpeg';
import four from '../../assets/dayphotos/4.jpeg';
import five from '../../assets/dayphotos/5.jpeg';
import six from '../../assets/dayphotos/6.jpeg';
import seven from '../../assets/dayphotos/7.jpeg';
import eight from '../../assets/dayphotos/8.jpeg';
import nine from '../../assets/dayphotos/9.jpeg';
import romania from '../../assets/dayphotos/romania.jpg';
import { AdventEvent } from './types.ts';

const images = [
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirteen,
  fourteen,
  fifteen,
  sixteen,
  seventeen,
  eighteen,
  nineteen,
  twenty,
  twentyone,
  twentytwo,
  twentythree,
  twentyfour,
  twentyfive
];
``;
export const OrderedAdventDays: AdventEvent[] = [
  {
    DayNumber: 9,
    Title: 'I love you',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/0Cgd3CK6D2hhbwmLRvtscH?si=3e9788127b764e8f',
    Image: images[0],
    Question: {
      Question: 'If you were a superhero, what would your superpower be and why?',
      Comment:
        'We will start off light, HotOnes style 😜. BTW you can use colors etc in the answer, I have plans for them later!',
      DustinResponse:
        'I would want to fly like Superman. ' +
        'I think the feeling of free flight (like a bird) would be one of the most exhilarating things in the world (it is in a freaking tin box with wings!)' +
        " Plus can't superman fly in space?"
    }
  },
  {
    DayNumber: 10,
    Title:
      'I love when we cuddle on the couch and watch TV. It makes all my worries and stresses disappear completely.',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/4QfikoJtCEiOj7G5R9m3sP?si=a258626ac1d042f5',
    Image: images[1],
    Question: {
      Question:
        'If you could have a personal theme song what would it be and why? It can absolutely be a few - a song that really captures who you are, that holds special memories, that just strikes that chord. Have fun with it bb',
      Comment:
        "Day two love!!! I hope you and your mom have a great day! <3 17 more days!! ALSO I'M SORRY THIS IS SO HARD!! I didn't think it would be then I spent hours listening to songs lol",
      DustinResponse:
        'Well if it was us entering the room it would be todays song of the day!! <br/> <br/> This is a really hard one for me though! One song that immediately comes to mind is Money - Pink Floyd. Not just because I like money hah, but I have early memories of lying in bed' +
        ' while my parents partied downstairs and hearing this song and just LOVING it. It has been one of my fav songs since I was little. The Saxaphone in it, the style, the lyrics. <br/><br/> ' +
        'If I wanted to get sad and think into my past I would say Scars - Papa Roach. I was in between like ten songs here hah, but I think my young years were spent feeling misunderstood and dealing with unrequited love. <br/><br/>' +
        "Today? It's Everlong - Foo Fighters. My relationship with you feels like its too good, that we are two souls merging. Then more generally, my life is just crazy good right now. I have you, my business, my house, my dog, like its insane. Can it feel this real forever? I hope so! You just gotta promise not to stop if I say when ;)"
    }
  },
  {
    DayNumber: 11,
    Title: 'Alex, I love your gorgeous hazel eyes <3.',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/5qlJg0Wbj3sslxfwiaRNpq?si=b9031c5afd424b88',
    Image: images[2],
    Question: {
      Question: 'When you were a kid what did you want to be when you grow up? Think as far back as you can!',
      Comment:
        'Okay okay, that last one was hard. I know your with your mom in Bucharest so I will keep it light for a few :P',
      DustinResponse:
        "Hahaha honestly... a cement truck driver. I remember I think it was kindergarden we had to draw what we wanted to be and that's what I drew! <br/>" +
        'I think theres something to that though. I was always fascanatied by machines and cars in general. The more buttons the better.<br/>' +
        "My grandparents (moms side) would get a new car every few years and it was my FAVOURITE day. I'd sit it in and press every button (literally every one) for hours."
    }
  },
  {
    DayNumber: 12,
    Title:
      'I absolutely adore the mornings we spend together having coffee and chatting. I miss those so much right now baby.',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/6O31zthNOIzdQcFLtEggnF?si=eba282ad31574cac',
    Image: images[3],
    Question: {
      Question: "What's your favorite part of the day and why?",
      Comment: "Still keeping it light bb!! I'll turn up the heat soon 🔥",
      DustinResponse:
        'For such a large part of my life I was a night owl. I loved driving at night (still do), being out at night, and just night in general. <br/>' +
        'Recently though, its become morning. I love the serenity of the early morning. I love how everything is still and the day is yet to begin. The day, first thing in the morning, still has so much potential and I find that beautiful.<br/>' +
        'And then, as my title alluded too, mornings with you are extra special. When we are together my mood is just elevated immediately (because we have amazing energy!!). When I get that in the morning it sets me up for such a wonderful day.<br/>'
    }
  },
  {
    DayNumber: 13,
    Title: 'I love when you wear that really bright red lipstick then kiss me so its all over my face <3',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/2bxVJeXMKGHijMD563Oqcr?si=4655c228b1cd4dee',
    Image: images[4],
    Question: {
      Question: 'What are your preferred way to receive physical affection? Do I this enough? Too much?',
      Comment: 'TWO WEEKS AWAY BABY!!!! Enough with the easy questions tho :P',
      DustinResponse:
        'I know we’ve talked about this before but honestly the physical affection in our relationship is new to me and absolutely amazing. <br/> <br/>' +
        'I’ve never been one for huge physical affection but with you it’s all different. I love holding hands with you, I love cuddling with you, I love kissing you and hugging you. <br /> <br />' +
        'Theres this electricity to it when we kiss that takes over my whole body. There’s this peaceful and home feeling when we cuddle on the couch/in bed (so much so that everything feels perfect in a way I have never felt before). And this very primal connection and desire when we have sex.' +
        'It’s absolutely amazing and I have never felt it before you baby <3'
    }
  },
  {
    DayNumber: 14,
    Title: 'I love how silly we can be together, with emojies, valentines, those snapchat things.',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/0vTKR5TQD03kASmEGlyDF3?si=428d09501cbe446b',
    Image: images[5],
    Question: {
      Question: 'What small things can I do to make your day?',
      Comment: "HAPPY ANNIVERSARY MY LOVE <3 <3 <3 - don't miss the bonus button after you answer ;)",
      BonusLink: '/dacs-events/view?id=1',
      DustinResponse:
        'You make me laugh all the time and that will just completely elevate my mood.' +
        '</br>' +
        'Like the silly jokes we have and silly thing we say. The animal videos (and funny romanian videos) you send me. The silly snapchats and the emojis. <br/>' +
        'And when you share cute drawings and things with me (like the pasta one) - it just melt my heart and makes me feel so loved and special. <br/>' +
        'I also love mushy good morning texts, they are the next best thing to waking up next to you and spending the morning togther. It really helps me start the day on the right foot <br/>'
    }
  },
  {
    DayNumber: 15,
    Title:
      'I love how you cherish the small things - the rose, the pasta kiss, all the mementos from the things we do together.',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/06kxa3al7bUqRRo5nAFduZ?si=f53ae123323d4f61',
    Image: images[6],
    Question: {
      Question: 'How can I make you feel sexy and attractive?',
      Comment: 'I love you more then the world baby <3 <3',
      DustinResponse:
        "This one is tough for me because I really don't feel attractive hah. What helps me is getting dressed up, wearing nice clothes, and having my super sexy girlfriend with me ;) <br/>" +
        "It feels really good when you complement me about clothes that I don't like (most of them hah). That time when you said you liked that blue shirt (that I HATE) for example and the Stampede one too! <br/>" +
        "I'm usually only comfortable in black shirts or my nice fitting dress clothes, and it means a lot to me when you say I looked attractive in my other shirts!" +
        "I also find when I'm with you I don't really think about how I look. I have you and that's all that matters <3 "
    }
  },
  {
    DayNumber: 16,
    Title: 'I love the little stories you tell me about your day and things you encounter',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/3Nf8oGn1okobzjDcFCvT6n?si=c46c0e026e894522',
    Image: images[7],
    Question: {
      Question:
        'DACS Engineering Co invents a device that lets us have infinite money, teleport around the earth, and change the weather. It runs purely on our love and using it more then once would suck all the love out of the world. It also stops working once we go to bed and anything we bought disappears. Time zones are normalized by the machine. What do we spend the day doing?',
      Comment: 'The song is a bit oniony-cutty!!! But the question is fun so it balances :) :)',
      DustinResponse:
        "I would start by taking us on a morning ski trip to the Swiss Alps (I think I'm still bitter about my 30th birthday ha) - but not all day, just that fresh snow early morning blue-bird day.<br/>" +
        "Then we'd teleport back to Calgary and make it a nice hot summer day and we'd take our motorcycles out for a picnic lunch somewhere in the mountains (hell if we have unlimited money maybe go buy two new ones). <br/>" +
        'Say its around 2-3PM now, so lets teleport to the most amazing beach in the world and spend 4 or so hours just relaxing. <br/>' +
        'For dinner we would find the most exclusive vegan resturant in the world and go have another chefs tasting with some drinks - but not nearly as much food so we can still move for the rest of the night! <br/>' +
        "After dinner, little bit drunk and feeling great, we'd teleport around to a few different clubs across the world and dance the night away. <br/>" +
        "Finally, after a long night of dancing, we would teleport to a yaught we had chartered before skiing that out in the middle of the calm ocean. We'd have the yaught to oursevles - so some under the stars on the ocean sex would be in order ;).<br/>" +
        "And then we would fall asleep in each others arms and wake back up in Calgary... assuming we didn't make a grave engineering error and just end up in the middle of the ocean as soon as we fall asleep :P."
    }
  },
  {
    DayNumber: 17,
    Title:
      'I love how you always want to sit next to me. It warms my heart and I love that we can touch and be mushy with one another in public.',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/3fDrZa4ksxA5lgi0utGu6k?si=3d6c6999dbef45d0',
    Image: images[8],
    Question: {
      Question: 'What was going through your mind during our first kiss?',
      Comment: '',
      DustinResponse:
        'I remember this like it was yesterday! It was that night we went to Sweet Loretta (Feb 10th!!) - our first date after I got back from Mexico (and second ever). <br/>' +
        'I was SO nervous yet excited to see you. I remember thinking how it felt like we had been dating and knew each other so well. The whole time, picking you up, making dinner, eating (until I actually kissed you) it was all I could think about!! <br/>' +
        'I knew I HAD to do it, I remember thinking how much I wanted to kiss you and how much I wanted to be with you, but I was so nervous. <br/>' +
        'Then when I finally did it felt so amazing. <br/>'
    }
  },
  {
    DayNumber: 18,
    Title:
      "I love your heart. By which I mean - I love the compassion and love you have for every living being. It's inspiring.",
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/0mWnUFBJKH4LE2Z8IMPg1D?si=32f61160c34a47f3',
    Image: images[9],
    Question: {
      Question:
        "Once again, DACS Engineering Co invents an amazing device that runs on our love!! This time its a device that downloads a specific skill set into your head at a world-class level. There's a catch though - you can only ever use the skill recreationally or you lose it! So no learning how to make a ton of money!",
      Comment:
        "I couldn't fit this in the title - but I think the world would be a WAY better place if everyone had even 1% of how much you care about everything.",
      DustinResponse:
        'This one is easy for me! Music. If I had to be more specific because DACS Engineering Co loves their limitations it would be Guitar, but I would really love to be one of those people that can play anything. <br/>' +
        "Music is just such a big part of my life and resonates with me so much. It can be such an expression of emotion that words just can't explain. It's also so cool how every single human is connected by it and it can be almost reduced to a formula.<br/>" +
        ' I have such a strong desire to make it myself, but its SUCH a time consuming hobby to get good at!! Plus, I could serenade you with songs ;) <br/> ' +
        'Then a close second? Cooking! I love food and also see it as such an expression of self (when your really good at it!). '
    }
  },
  {
    DayNumber: 19,
    Title:
      'I love how true, genuine and beautiful your smile is. Whenever I see you smile this energy just radiates out from you and can just totally envelope me in warmth.',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/3VpkXfVPAJCEK52FFnf5Th?si=acbb2394fc5c4578',
    Image: images[10],
    Question: {
      Question: 'Did you have a crush on anyone in school?  Tell me about it!',
      Comment: "This ones just light and fun :). I'm thinking like early junior high when it was still simple.",
      DustinResponse:
        'Haha I had a few! The one that stands out though is this girl named Sydney. We sat next to one another in computer class in like grade 7/8 maybe? <br/>' +
        'She was always so nice and actually talked to me and made jokes with me all the time, so obviously that means she loved me right? I was not popular in those years so any attention was huge to me. Honestly I think a rock could have shown my some attention and I would have fallen head over heels for it <br/>' +
        'Then at one of those like jr high dances I actually asked her out. My friend Mark talked me into it - I was SO TERRIFIED. She very politely said no, I pretended it was OK but I remember feeling very sad.'
    }
  },
  {
    DayNumber: 20,
    Title: 'I love you',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/7jji8nfxJ84AJkUSpgruPL?si=f3d1c2794bf14b52',
    Image: images[11],
    Question: {
      Question:
        'Think about all the things we have done together so far. Is there a specific moment or two that stick out in your heart and mind above the others?',
      Comment:
        "I don't mean an event or whole day or anything like that - just any particular moment that shines brighter then all of the other amazing ones.",
      DustinResponse:
        "I have a few! At City and Colour the first time I wrapped my arms around you and just held you as we listened to the show. That was one of those 'nothing else matters than this moment' feelings and I cherish it (and have had many more!). <br/>" +
        'The next one was the weekend we had rented that truck to do gardening/yard work. I went to take the truck back and you said behind. When I came home, you were here just sitting on the couch with Leia watching TV. Something felt <i>so</i> perfect about you just being here when I got back. <br/>' +
        "I have so many more, but I said one or two, so that's all for now my love :)."
    }
  },
  {
    DayNumber: 21,
    Title: 'I love your voice. I love hearing it, I love your accent, and I love how happy it makes me to hear. ',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/1Rvl8qsKJurfFTyWLBI9ib?si=f487ee0b189b44ca',
    Image: images[12],
    Question: {
      Question: 'When you were a kid did you have a favourite item? Like a stuffy/blanket/etc!',
      Comment: '',
      DustinResponse:
        'I had two (well.. three kinda)! So when I was really young I had this white blanket that I creatively named blankee. It was small, like a baby blanket, and had these elephants on it (I think!). I carried that thing EVERYWHERE <br/>' +
        "Eventually it became an absolute tattered rag and I had to get rid of it :(. At some point near there I also got 'big orange'. This normal sized comforter that was bright orange. I have no idea why I loved that blanket so much but I did for YEARS. <br/>" +
        "And there's def a pattern here... because I kept that until it was full of holes and falling apart. Eventually my parents made me throw it out and got me a new white one. I DESPISED it and I was so mad, so my grandma bought a bunch of orange <br/>" +
        'fabric dye and dyed my new quilt orange (bless her hear hah). It was never the same though :('
    }
  },
  {
    DayNumber: 22,
    Title: 'I love the little bit of gangster in you <3',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/6rLQZW36LaEUGWl3sWTg7v?si=136e8de4a19e4384',
    Image: images[13],
    Question: {
      Question:
        'We are both pretty adrenaline seeking people. What are your top 2 or 3 high-adrenaline bucket list items and why?',
      Comment:
        'FOUR MORE SLEEPS BABY!!! By high adrenaline I just mean like activities - not retiring on the beach somewhere :)',
      DustinResponse:
        "The first is for sure sky diving. I've always wanted to take the course and get to a solo free fall jump. I just can't think of anything more exhilarating then falling through the sky completely weightless. The view, the wind, the absolutely almost helplessness? It would be wild. <br/>" +
        'Second one would be a driving course in a proper race car around a proper track. I want to learn to push corners at insanely high speeds in a for-purpose vehicle. Not just for the rush, but because I find the engineering incredible and to experience that first hand would be so satisfying. <br/>' +
        "Or a motorcycle... but I don't think I'm that insane hah. And then thirdly, which I hope to do soon, is to get my glider license. I think it would be very similar to skydiving in that helpless/figure it out but then also come with the feeling of absolute freedom of flying."
    }
  },
  {
    DayNumber: 23,
    Title:
      "I love your energy and how it is so infectious. Being with you make me actually feel happy in a way I've never experienced.",
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/3SQgjukE8uHGI3ZwBtDetP?si=818c0d8e08044f88',
    Image: images[14],
    Question: {
      Question:
        'DACS© Chemistry Co (a subsidiary of DACS© Engineering) invites a syrum that lets you talk to animals. What animal (or two) has the worst personality and what is it like?',
      Comment: '3 more sleeps until we can lay together and just forget the world bb <3...',
      DustinResponse:
        'You probably see this one coming but.... it has to be Llamas. They would be so sassy but aloof at the same time. Then you just KNOW they are gonna shoot you a little smile that is super cute and melts your heart so you give them another chance. <br/>' +
        "BUT THEN they start being snippy and aloof again. Dropping a bunch of 'whatever mans'. Completely indifferent to how you think and feel. <br/>" +
        'Then finally, they close it all off by spitting on you...  '
    }
  },
  {
    DayNumber: 24,
    Title: 'I love your Romanian culture and when you share it with me (and soon I get to see it!!!)',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/6Ac4NVYYl2U73QiTt11ZKd?si=e2e29c31e70d4f1f',
    Image: images[15],
    Question: {
      Question:
        "We made a billion dollars selling some of DACS© Engineering Co inventions!! We're opening a restaurant with the money - tell me about it.",
      Comment: '2....',
      DustinResponse:
        "Okay, so obviously it would be called DACS and it would be vegan. I'm thinking we go space-themed - you know those restaurants that are all about 'science food' (molecular gastronomy) - we'd go that route. <br/>" +
        'So sort of a space themed fine-dining resturant that is also just super romantic. Like, dark lightning, high end furniture, but also futuristic and sci-fi like. Lots of deep reds and hearts everywhere. <br/>' +
        "Now for a signature dish, we have to play off StarTrek  but also not cross any copywrite lines. So I'm thinking we do a vegan version of 'Gagh' - its the Klingon live worm food. No idea how to make it, but it would be earthy " +
        'tasting and very savoury with some really nice sauce/soup to go with it. <br/>' +
        'PS - this is clearly a winning resturant idea'
    }
  },
  {
    DayNumber: 25,
    Title: 'I love the way you kiss me, its so deep and authentic',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/5L2l7mI8J1USMzhsmdjat9?si=1721e0f838314274',
    Image: images[16],
    Question: {
      Question: 'How would you rate my kissing skills on a scale of 1 to 10 and what could I do to make it an 11?',
      Comment: 'Because its obviously already a 10 ;)',
      DustinResponse:
        'God I love kissing you and even writing this is turning me on.... honestly I would truly give you a 10. <br/>' +
        'I love how deep and real it feels when we kiss and how it just fills my body with energy! <br/>' +
        "It's an 11 when you kiss my neck and other places.... I love that <3"
    }
  },
  {
    DayNumber: 26,
    Title: 'I love how you love your motorcycle, your car, and driving/riding so much (and that you ride in general).',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/4njseCGxWeZUksjhrqkleT?si=21fc8d75c0524a91',
    Image: images[24],
    Question: {
      Question: 'What’s one hobby you’ve never tried (or tried recently) but would love for us to do together?',
      Comment: 'ITS TOMORROW BABY!!!',
      DustinResponse:
        "I would love for us to take up some sort of sport together!! I know we've talked about Tennis which I'm totally into! Or Badminton is also good (poor peoples Tennis really hah). <br/>" +
        "Also, I really hope we can ski together a lot this winter! I've sort of dropped that hobby and I love it and it would be so much better with you!"
    }
  },
  {
    DayNumber: 27,
    Title:
      'I love you how beautiful you look all the time. Sometimes when the sun hits your face its almost surreal <3 ',
    IsOpen: false,
    IsBucharest: true,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/1zv3WFUbZ5vPxFq9I2jAU1?si=eee3c3cc386742ef',
    Image: romania,
    Question: {
      BonusLink: '/dacs-events/view?id=2',
      Question: "What's the one thing you are most excited to show me about Romania?",
      Comment: 'THE DAY IS HERE BABY',
      DustinResponse:
        'I JUST WANNA SEE YOU AND KISS YOU AND LOVE YOU BABY <3 <3 <br/>' +
        "I am so so so excited for our adventure though. Its hard to pick one, but I'm really excited for Vama!! And the castles... and Cluj... and all of it hah"
    }
  },
  {
    DayNumber: 28,
    Title: 'I love when we sync and share energies together',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/273cv9sESQzXxMqEkhXdm4?si=7b0d31ccbfbd4444',
    Image: images[19],
    Question: {
      Question:
        "DACS© Chemistry Co© has invented an anti-aging syrum. There's a catch though - it doesn't just slow aging, it stops it completely. You will never age or die of natural causes. Do you take it?",
      Comment: '',
      DustinResponse:
        'This is a really hard one! On one hand, the idea of being basically invulnerable is really intriguing to me. I so want to see the future and see where humanity goes. <br/>' +
        'But on the other hand, death is part of the human experience and a big part of what makes us human. The thought of everyone getting old around me also just sounds terrible.' +
        'I think the futurist and explorer in me would win out and I would take it....'
    }
  },
  {
    DayNumber: 29,
    Title: 'I love how we go on these awesome adventures together, like motorcycle rides and... you know.. this one :D',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/5vvoFE63Z7yw2TTj4icSDn?si=c2ebb8bc99304c71',
    Image: images[20],
    Question: {
      Question:
        '"DACS© Engineering Co© has to one up the anti-aging syrum.... they made the DACS Machine real and perfected time travel. No causality loops, no risk, nothing. Where do you go first?',
      Comment: '',
      DustinResponse:
        "My first instinct is to say 300-400 years in the future, just to see if humanity made it and we are travelling the stars or not. But then I think about it and that sounds terrifying, what if we haven't and its just a hole left where humanity was? That's super depressing sorry hah. <br/>" +
        "So I think I'd explore the past first! I love the 60's era and I'd start there - it had so much character I think (like 60's America) - despite the social issues hah. Then dinosaurs and that era! <br/>" +
        'It would also be very interesting to visit the world war eras and see how things were. Not a vacation, but a really eye opening experience.'
    }
  },
  {
    DayNumber: 30,
    Title:
      'I love your energy when I am cooking or we are getting ready and how you share things and we talk and just have fun together',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: false,
    SongUrl: 'https://open.spotify.com/track/1gihuPhrLraKYrJMAEONyc?si=ecfb1e6e64ea46f8',
    Image: images[23],
    Question: {
      Question: 'No question today baby, I should be here with you lets just go enjoy Vama!!',
      Comment: '',
      DustinResponse: ''
    }
  },
  {
    DayNumber: 31,
    Title: 'I love you. HAPPY BIRTHDAY BABY',
    IsOpen: false,
    IsBucharest: false,
    IsFinal: true,
    SongUrl: 'https://open.spotify.com/track/4RY96Asd9IefaL3X4LOLZ8?si=929473c12b024867',
    Image: images[22],
    Question: {
      BonusLink: '/dacs-events/view?id=3',
      Question: '',
      Comment: '',
      DustinResponse: ''
    }
  }
];
