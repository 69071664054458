import { AdventEvent } from '../types.ts';
import { Box, Flex } from '@chakra-ui/react';

type Props = {
  event: AdventEvent;
  isToday: boolean;
};

export const FinalBox = (props: Props) => {
  return (
    <Box
      backgroundColor={'black'}
      border='1px solid black'
      backgroundImage={`url(${props.event.Image})`}
      backgroundSize={'cover'}
      display='flex'
      alignItems='center'
      zIndex={'99'}
      boxShadow={
        props.isToday
          ? `0px 0px 2px #ec1c2f, 0 0 30px #ec1c2f, 0 0 45px rgba(255, 69, 0, 0.4), 0 0 60px rgba(255, 69, 0, 0.2)`
          : 'unset'
      }
      position={'relative'}
      // _before={{
      //   content: '""',
      //   position: 'absolute',
      //   top: 0,
      //   left: 0,
      //   right: 0,
      //   bottom: 0,
      //   backgroundColor: 'rgba(0, 0, 0, 0)',
      //   zIndex: 1
      // }}
      justifyContent='center'>
      <Box>
        {props.isToday && (
          <Flex
            opacity={1}
            flexDir={'column'}
            alignItems={'center'}
            color={'white'}
            zIndex={'2'}
            textShadow={'-2px -2px 0 #000,2px -1px 0 #000, -2px  2px 0 #000,2px  2px 0 #000;'}></Flex>
        )}
      </Box>
    </Box>
  );
};
