import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { AppRoles } from '../constants/appRoles';
import { ApplicationRoutes } from '../constants/appRoutes';
import { NarrowLayout } from '../components/layout/narrowLayout.tsx';

export const DacsEventRoutes: RouteItem = {
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.User],
  layout: <NarrowLayout hideCalendar={true} />,
  subItems: [
    {
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.dacsEvents.view,
      allowedRoles: [AppRoles.User],
      async lazy() {
        const component = await import('../features/dacsEvents/viewer/dacsEventViewer.tsx');
        return { Component: component.DacsEventViewer };
      }
    },
    {
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.dacsEvents.view,
      allowedRoles: [AppRoles.User],
      async lazy() {
        const component = await import('../features/dacsEvents/viewer/dacsEventViewer.tsx');
        return { Component: component.DacsEventViewer };
      }
    },
    {
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.dacsEvents.timeline,
      allowedRoles: [AppRoles.User],
      async lazy() {
        const component = await import('../features/dacsEvents/timeline.tsx');
        return { Component: component.Timeline };
      }
    },
    {
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.dacsEvents.random,
      allowedRoles: [AppRoles.User],
      async lazy() {
        const component = await import('../features/adventCalendar/randomDay.tsx');
        return { Component: component.RandomDay };
      }
    }
  ]
};
