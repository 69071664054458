import { Box, Button, Flex, Heading, Icon, useDisclosure } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { AdventEvent } from '../types.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { AnswerView } from './answerViewr.tsx';
import { useNavigate } from 'react-router-dom';

type Props = {
  event: AdventEvent;
  alexResponse: string;
};
const MotionBox = motion(Box);

export const OpenBox = (props: Props) => {
  const disclosure = useDisclosure();
  const nav = useNavigate();
  return (
    <Box border='1px solid black' display='flex' alignItems='center' position={'relative'} justifyContent='center'>
      {' '}
      <Box position='relative' w='100%' h='100%'>
        {/* Left Door */}
        <MotionBox
          position='absolute'
          top='0'
          left='0'
          w='50%'
          h='100%'
          transformOrigin='left'
          animate={{
            rotateY: [0, -90] // Rotate from -90 (closed) to 0 (opened)
          }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            delay: 0.5
          }}
          boxShadow='0px 4px 10px rgba(0, 0, 0, 0.3)'>
          <Box
            backgroundImage={`url(${props.event.Image})`}
            backgroundSize={'cover'}
            pos={'absolute'}
            top={0}
            left={0}
            backgroundColor={'blue'}
            w={'100%'}
            zIndex={'0'}
            height={'100%'}
          />
        </MotionBox>

        {/* Right Door */}
        <MotionBox
          position='absolute'
          top='0'
          right='0'
          w='50%'
          h='100%'
          transformOrigin='right'
          animate={{
            rotateY: [0, 90] // Rotate from 90 (closed) to 0 (opened)
          }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            delay: 0.5
          }}
          boxShadow='0px 4px 10px rgba(0, 0, 0, 0.3)'>
          <Box
            backgroundImage={`url(${props.event.Image})`}
            backgroundSize={'cover'}
            pos={'absolute'}
            top={0}
            left={0}
            w={'100%'}
            zIndex={'0'}
            flexDir={'column'}
            alignItems={'center'}
            height={'100%'}></Box>
        </MotionBox>

        <Box marginTop={'4px'}>
          <Flex flexDir={'column'}>
            <Box padding={'8px'} alignSelf={'end'} cursor={'pointer'} onClick={disclosure.onOpen}>
              <Icon as={FontAwesomeIcon} icon={faEye}></Icon> View #{props.event.DayNumber}
            </Box>

            <Flex
              height={'100%'}
              flexDir={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              textAlign={'center'}
              fontWeight='bold'
              color='black'
              opacity={1}
              gap={'10px'}
              backgroundColor={'white'}>
              <Heading fontSize={'14px'}>{props.event.Title}</Heading>
              <Button
                onClick={() => {
                  window.open(props.event.SongUrl);
                }}
                variant={'tertiary'}
                rightIcon={<Box> 🎵🎵</Box>}>
                Song of the day
              </Button>
              {props.event.Question?.BonusLink && (
                <Button
                  size='lg'
                  onClick={() => {
                    nav(props.event.Question?.BonusLink!);
                  }}
                  leftIcon={<Box>🎁</Box>}
                  colorScheme='teal'
                  bgGradient='linear(to-r, primary.500, secondary.500)'
                  boxShadow='0px 0px 20px rgba(0, 255, 170, 0.6)'
                  _hover={{
                    bgGradient: 'linear(to-r, green.500, teal.500)',
                    boxShadow: '0px 0px 30px rgba(0, 255, 170, 0.8)'
                  }}
                  animation='pulse 2s infinite'
                  sx={{
                    '@keyframes pulse': {
                      '0%': { transform: 'scale(1)' },
                      '50%': { transform: 'scale(1.05)' },
                      '100%': { transform: 'scale(1)' }
                    }
                  }}>
                  Bonus
                </Button>
              )}
            </Flex>
          </Flex>
        </Box>
      </Box>
      <AnswerView event={props.event} disclosure={disclosure} alexResponse={props.alexResponse} />
    </Box>
  );
};
