import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { NarrowLayout } from '../components/layout/narrowLayout';
import { AppRoles } from '../constants/appRoles';
import { ApplicationRoutes } from '../constants/appRoutes';

export const ReminderRouteItems: RouteItem = {
  text: 'Reminders',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.User],
  layout: <NarrowLayout />,
  subItems: [
    {
      text: 'Manage Reminders',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.reminders.manage,
      allowedRoles: [AppRoles.User],
      async lazy() {
        const component = await import('../features/reminderTemplates/ReminderTemplates');
        return { Component: component.ReminderTemplates };
      }
    }
  ]
};
