import { formatDate } from 'date-fns';

export function formatDateDateOnly(date: string | Date): string {
  return formatDate(date, 'dd-MMM-yyyy');
}

export function parseDateOnly(date: string): Date {
  const split = date.split('-');
  return new Date(parseInt(split[0]), parseInt(split[1]) - 1, parseInt(split[2]));
}
