import { extendTheme } from '@chakra-ui/react';
import { ChakraThemeConfig } from '@cksoftware/react-base';
import { alertTheme } from './components/alert';
import { buttonTheme } from './components/button';
import { cardTheme } from './components/card';
import { drawerTheme } from './components/drawer';
import { formTheme } from './components/form';
import { formLabelTheme } from './components/formLabel';
import { headerTheme } from './components/heading';
import { inputTheme } from './components/input';
import { modalTheme } from './components/modal';
import { numberInputTheme } from './components/numberInput';
import { selectTheme } from './components/select';
import { switchTheme } from './components/switch';
import { tabsTheme } from './components/tab';
import { tableTheme } from './components/table';
import { textTheme } from './components/text';

const themeConfig: ChakraThemeConfig = {
  paddings: {
    tableHeightBottomPadding: 190
  }
};

export const ChakraTheme = extendTheme({
  ...themeConfig,
  components: {
    Input: inputTheme,
    Button: buttonTheme,
    Select: selectTheme,
    NumberInput: numberInputTheme,
    Form: formTheme,
    Text: textTheme,
    Modal: modalTheme,
    Heading: headerTheme,
    Tabs: tabsTheme,
    Card: cardTheme,
    Table: tableTheme,
    Switch: switchTheme,
    Drawer: drawerTheme,
    Alert: alertTheme,
    FormLabel: formLabelTheme
  },

  styles: {
    global: {
      body: {
        background: '#faf9f67f'
      }
    }
  },
  colors: {
    primary: {
      '50': '#ffe5e7',
      '100': '#fbb5b9',
      '200': '#f6848b',
      '300': '#f1545c',
      '400': '#ec1c2f',
      '500': '#d30317',
      '600': '#a00211',
      '700': '#6e010c',
      '800': '#3c0006',
      '900': '#0a0000'
    },
    secondary: {
      '50': '#f5e6ff',
      '100': '#dac1ff',
      '200': '#c19bff',
      '300': '#a975ff',
      '400': '#914fff',
      '500': '#7836e6',
      '600': '#5e2bb4',
      '700': '#451f82',
      '800': '#2b1451',
      '900': '#120a21'
    },
    tertiary: {
      '50': '#ffe9ed',
      '100': '#febdca',
      '200': '#fb91a6',
      '300': '#f86582',
      '400': '#f4395f',
      '500': '#db2046',
      '600': '#a71835',
      '700': '#730f24',
      '800': '#3f0713',
      '900': '#0b0003'
    }
  }
});
