import { Text } from '@chakra-ui/react';
import { CardPageContainer } from '@cksoftware/react-base';
import { Timeline } from '../../features/dacsEvents/timeline.tsx';

export const Home = () => {
  return (
    <CardPageContainer header={'Hello Alex'}>
      <Text>You are my world.</Text>
      <Timeline />
    </CardPageContainer>
  );
};
